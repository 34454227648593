/*** Headline ***/

.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2 {
	margin-top: -60px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--1
	+ .mco-view-component-container-basic-headline--2
	h2 {
	font-size: clamp(20px, 8vw, 24px) !important;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3 {
	margin-top: -60px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	+ .mco-view-component-container-basic-text {
	margin-top: -40px;
}
.mco-elements
	> .mco-view-component-container-basic-headline--2
	+ .mco-view-component-container-basic-headline--3
	h3 {
	font-size: clamp(18px, 8vw, 20px) !important;
}
.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -81px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-mski-facilities {
	margin-top: -64px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-mski-liftstate {
	margin-top: -64px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-mski-piststate {
	margin-top: -64px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-mski-snowreport {
	margin-top: -40px;
}
.mco-view-component-container-basic-headline + .mco-view-component-container-mski-weather {
	margin-top: -40px;
}

@media all and (min-width: 1280px) {
	.mco-elements
		> .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2 {
		margin-top: -100px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3 {
		margin-top: -100px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--1
		+ .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-text {
		margin-top: -80px;
	}
	.mco-elements
		> .mco-view-component-container-basic-headline--2
		+ .mco-view-component-container-basic-headline--3
		+ .mco-view-component-container-basic-text {
		margin-top: -80px;
	}
	.mco-view-component-container-basic-dropdown-group
		+ .mco-view-component-container-basic-dropdown-group {
		margin-top: -121px;
	}
	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-mski-facilities {
		margin-top: -100px;
	}
	.mco-view-component-container-basic-headline + .mco-view-component-container-mski-liftstate {
		margin-top: -100px;
	}
	.mco-view-component-container-basic-headline + .mco-view-component-container-mski-piststate {
		margin-top: -100px;
	}
	.mco-view-component-container-basic-headline
		+ .mco-view-component-container-mski-snowreport {
		margin-top: -64px;
	}
	.mco-view-component-container-basic-headline + .mco-view-component-container-mski-weather {
		margin-top: -80px;
	}
}
