@import url("./ticketoverview.css");

.mco-ski-shop-header-steps {
	display: none !important;
}
.mco-ski-shop-header-steps__step * {
	color: #ffffff !important;
}
.mco-ski-shop-header-steps__step > div > span {
	font-size: 16px !important;
}
.mco-form-button-wrapper button {
	border-radius: 24px !important;
	font-size: 18px !important;
	height: 48px !important;
}
.mco-ski-shop-left-right-combo__left:not(.mco-ski-shop-left-right-combo__left--no-background) {
	background: var(--mco-palette-background-light) !important;
}
.mco-ski-shop-credit * {
	color: #ffffff !important;
}
.mco-ski-shop-credit span {
	color: var(--mco-palette-secondary-light) !important;
	font-size: 16px !important;
}
.mco-ski-shop-order-submit__amount {
	color: #ffffff !important;
}
.mco-ski-shop-order-submit__amount span {
	color: var(--mco-palette-secondary-light) !important;
	font-size: 16px !important;
}
.mco-ski-shop-cart-data-delivery-info__button-group button {
	border-radius: unset !important;
}
.mco-ski-shop-register-keycard button {
	height: 100% !important;
	border-radius: unset !important;
}
.mco-ski-shop-register-form-check__button-wrapper button,
.mco-ski-shop-order-submit__button-wrapper button,
.mco-ski-shop-login-form__field .mco-form-button-wrapper button {
	padding-left: 40px !important;
	padding-right: 40px !important;
}

@media all and (min-width: 960px) {
	.mco-ski-shop-left-right-combo:not(.mco-ski-shop-left-right-combo--no-background) {
		background: linear-gradient(90deg, #e5e5e5 50%, #fff 50%) !important;
	}
}
