/*** Layout ***/

/*** Headline ***/

h1 {
	hyphens: auto !important;
}
.mco-view-component-container-basic-headline > * {
	color: #003a56 !important;
}

/*** List ***/

.mco-list-01__list svg {
	margin: 4px 8px 0 0 !important;
}

/*** Image-Text ***/

@media all and (min-width: 600px) {
	.mco-imagetext-01 {
		align-items: center;
	}
}
@media all and (min-width: 1280px) {
	.mco-imagetext-01 {
		grid-column-gap: 70px !important;
	}
}

/*** References ***/

.mco-references-01__list-item .mco-references-01__list-label {
	color: #e63311 !important;
}
.mco-references-01__list-item .mco-references-01__list-label:hover {
	color: #a3240c !important;
}
.mco-references-01__list-item svg {
	margin-top: 4px !important;
}

/*** Singleimage ***/

.mco-view-component-container-basic-singleimage {
	overflow: hidden;
}
.mco-singleimage-02__content {
	align-items: flex-start !important;
	padding-left: 48px !important;
	text-align: left !important;
}
.mco-singleimage-02__content .mco-singleimage-02__content-title {
	position: relative;
}
.mco-singleimage-02__content .mco-singleimage-02__content-title:before {
	background: #e63311;
	border-radius: 50%;
	content: "";
	height: 16px;
	left: -32px;
	position: absolute;
	top: 8px;
	width: 16px;
}
.mco-singleimage-02__content .mco-singleimage-02__content-title:after {
	background: #e63311;
	content: "";
	height: 500px;
	left: -25px;
	position: absolute;
	top: -490px;
	width: 2px;
}

/*** Gallery ***/

.mco-view-component-container-basic-gallery {
	padding-right: 0 !important;
}
.mco-gallery-04-swiper {
	display: flex;
	flex-direction: column-reverse;
	gap: 40px;
}
.mco-gallery-04 .swiper-pagination {
	bottom: auto !important;
	position: relative !important;
}
.mco-gallery-04 .swiper-pagination-bullet {
	background: none !important;
	border: 2px solid #e63311 !important;
	border-radius: 50% !important;
	height: 12px !important;
	margin: 0 14px !important;
	opacity: 1 !important;
	position: relative !important;
	width: 12px !important;
}
.mco-gallery-04 .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #e63311 !important;
}
.mco-gallery-04
	.swiper-pagination-bullet.swiper-pagination-bullet-active
	+ .swiper-pagination-bullet::before {
	background: #e63311;
	content: "";
	height: 2px;
	left: -32px;
	position: absolute;
	top: 3px;
	width: 32px;
}
.mco-gallery-04
	.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)
	+ .swiper-pagination-bullet::before {
	background-image: linear-gradient(
		90deg,
		#e63311,
		#e63311 50%,
		transparent 50%,
		transparent 100%
	);
	background-size: 8px 2px;
	border: none;
	content: "";
	height: 2px;
	left: -30px;
	position: absolute;
	top: 3px;
	width: 28px;
}
.mco-gallery-04 .mco-gallery-04-swiper-nav__prev,
.mco-gallery-04 .mco-gallery-04-swiper-nav__next {
	background: #003a56 !important;
	border-radius: 50% !important;
	display: none !important;
	height: 48px !important;
	top: calc(50% - 42px) !important;
	width: 48px !important;
}
.mco-gallery-04 .mco-gallery-04-swiper-nav__next.swiper-button-disabled,
.mco-gallery-04 .mco-gallery-04-swiper-nav__prev.swiper-button-disabled {
	display: none !important;
}

@media all and (min-width: 960px) {
	.mco-gallery-04-swiper {
		gap: 48px;
	}
	.mco-gallery-04 .swiper-pagination-bullet {
		height: 16px !important;
		margin: 0 20px !important;
		width: 16px !important;
	}
	.mco-gallery-04
		.swiper-pagination-bullet.swiper-pagination-bullet-active
		+ .swiper-pagination-bullet::before {
		left: -42px;
		top: 5px;
		width: 42px;
	}
	.mco-gallery-04
		.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)
		+ .swiper-pagination-bullet::before {
		left: -40px;
		top: 5px;
		width: 38px;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-gallery {
		padding-right: 16px !important;
	}
	.mco-gallery-04 .mco-gallery-04-swiper-nav__prev,
	.mco-gallery-04 .mco-gallery-04-swiper-nav__next {
		display: flex !important;
	}
	.mco-gallery-04 .mco-gallery-04-swiper-nav__prev {
		left: 16px !important;
	}
	.mco-gallery-04 .mco-gallery-04-swiper-nav__next {
		right: 16px !important;
	}
}

/*** Download ***/

.mco-view-component-container-basic-download-group > div + div {
	margin-top: 2px !important;
}
.mco-view-component-container-basic-download > div {
	background: #ffffff;
	padding: 8px !important;
}
.mco-download-01__title {
	line-height: normal !important;
}
.mco-download-01__caption {
	display: block !important;
	margin-top: 4px !important;
}

/*** Dropdown ***/

.mco-dropdown-01__headline {
	color: #003a56 !important;
	font-size: clamp(20px, 8vw, 24px) !important;
}
.mco-dropdown-01__summary {
	background: #ffffff !important;
}
.mco-dropdown-01__summary.Mui-expanded {
	background: none !important;
}
.mco-dropdown-01__summary > div:first-child {
	margin: 16px 0 !important;
}

/*** Singleteaser ***/

.mco-singleteaser-01 {
	background: #00293d !important;
}
.mco-view-component-container-basic-singleteaser--summer .mco-singleteaser-01 {
	background: #333b24 !important;
}
.mco-singleteaser-01 > div {
	margin: 0 auto;
	max-width: 1440px !important;
}
.mco-singleteaser-01__content {
	border: none !important;
	padding: 32px !important;
}
.mco-singleteaser-01__addition {
	color: #ffffff !important;
	font-weight: 900 !important;
	margin-bottom: 8px !important;
}
.mco-singleteaser-01__title {
	color: #ffffff !important;
	margin-bottom: 24px !important;
}
.mco-singleteaser-01__text {
	color: #ffffff !important;
}
.mco-singleteaser-01__button {
	justify-content: flex-start !important;
	margin-top: 24px !important;
}
.mco-singleteaser-01__button .MuiButton-endIcon {
	margin-right: -16px !important;
}

@media all and (min-width: 960px) {
	.mco-singleteaser-01__content {
		flex-basis: 50% !important;
		max-width: 50% !important;
		padding: 64px !important;
	}
	.mco-singleteaser-01__image {
		flex-basis: 50% !important;
		max-width: 50% !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-singleteaser-01__content {
		padding: 140px 96px 96px !important;
	}
	.mco-singleteaser-01__button {
		margin-top: auto !important;
	}
}

/*** Teasergroup - Slider ***/

.mco-view-component-container-basic-teaser--slider {
	overflow: hidden;
	padding-left: 16px !important;
	padding-right: 0 !important;
	position: relative;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__text {
	font-size: 16px !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__headline {
	color: #003a56 !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-03__content {
	grid-gap: 0 !important;
	grid-template-rows: auto !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-03__image img {
	transition: transform 300ms ease !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-03__content::before {
	background: rgba(0, 41, 61, 0.5) !important;
}
.mco-view-component-container-basic-teaser--slider .mco-teaser-03__title {
	text-align: center !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination {
	bottom: auto !important;
	flex-wrap: wrap;
	padding-right: 16px;
	position: relative !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet:first-child {
	margin-left: 0 !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet:last-child {
	margin-right: 0 !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet {
	background: none !important;
	border: 2px solid #e63311 !important;
	border-radius: 50% !important;
	height: 12px !important;
	margin: 0 14px !important;
	opacity: 1 !important;
	position: relative !important;
	width: 12px !important;
}
.mco-view-component-container-basic-teaser--slider
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #e63311 !important;
}
.mco-view-component-container-basic-teaser--slider
	.swiper-pagination-bullet.swiper-pagination-bullet-active
	+ .swiper-pagination-bullet::before {
	background: #e63311;
	content: "";
	height: 2px;
	left: -32px;
	position: absolute;
	top: 3px;
	width: 32px;
}
.mco-view-component-container-basic-teaser--slider
	.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)
	+ .swiper-pagination-bullet::before {
	background-image: linear-gradient(
		90deg,
		#e63311,
		#e63311 50%,
		transparent 50%,
		transparent 100%
	);
	background-size: 8px 2px;
	border: none;
	content: "";
	height: 2px;
	height: 2px;
	left: -30px;
	position: absolute;
	top: 3px;
	width: 28px;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
	align-items: center;
	background: #003a56 !important;
	border-radius: 50% !important;
	display: flex;
	height: 40px !important;
	justify-content: center;
	width: 40px !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
	left: -8px !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next {
	right: 8px !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-disabled {
	opacity: 0 !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next svg,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev svg {
	width: 24px !important;
}
.mco-view-component-container-basic-teaser--slider .swiper-button-next svg path,
.mco-view-component-container-basic-teaser--slider .swiper-button-prev svg path {
	fill: #ffffff !important;
}

@media all and (min-width: 960px) {
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01--slider {
		display: flex;
		gap: 56px;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox {
		display: flex !important;
		flex: 1;
		flex-direction: column !important;
		grid-template-rows: auto 1fr 1fr !important;
		margin: 0 0 80px !important;
		min-width: 300px;
	}
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-list-01__textbox
		.mco-teaser-list-01__button {
		margin-top: auto !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__swiperRoot {
		flex: 3;
		overflow: hidden;
		padding-bottom: 80px !important;
		padding-left: 64px !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-03__content::before {
		opacity: 0;
		transition: opacity 300ms ease;
	}
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-03:hover
		.mco-teaser-03__content::before {
		opacity: 1;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-03__title {
		opacity: 0;
		transition: opacity 300ms ease;
	}
	.mco-view-component-container-basic-teaser--slider
		.mco-teaser-03:hover
		.mco-teaser-03__title {
		opacity: 1;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-pagination {
		bottom: 0 !important;
		justify-content: flex-start !important;
		left: 0 !important;
		position: absolute !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-pagination-bullet {
		height: 16px !important;
		margin: 0 20px !important;
		width: 16px !important;
	}
	.mco-view-component-container-basic-teaser--slider
		.swiper-pagination-bullet.swiper-pagination-bullet-active
		+ .swiper-pagination-bullet::before {
		left: -42px;
		top: 5px;
		width: 42px;
	}
	.mco-view-component-container-basic-teaser--slider
		.swiper-pagination-bullet:not(.swiper-pagination-bullet-active)
		+ .swiper-pagination-bullet::before {
		left: -40px;
		top: 5px;
		width: 38px;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-next,
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
		top: calc(50% - 20px) !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-prev {
		left: 0 !important;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-button-next {
		right: 56px !important;
	}
}

@media all and (min-width: 1280px) {
	.mco-view-component-container-basic-teaser--slider {
		padding-left: 0 !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01--slider {
		margin-left: calc((100% - 1280px) / 2) !important;
		padding-left: 64px !important;
	}
	.mco-view-component-container-basic-teaser--slider .mco-teaser-list-01__textbox {
		min-width: 355px;
	}
	.mco-view-component-container-basic-teaser--slider .swiper-pagination {
		left: 64px !important;
	}
}

/*** Form ***/

.mco-form-headline > p {
	color: #003a56 !important;
}
.mco-form-input,
.mco-form-select,
.mco-form-date {
	background: #ffffff !important;
}
.mco-form-button-wrapper {
	flex: unset !important;
	margin-left: auto !important;
}

/*** Table ***/

.mco-table-01__row th {
	background: none !important;
	color: #00202f !important;
	font-size: 16px !important;
}
.mco-table-01__row th strong {
	font-family: "Nunito Sans";
	font-weight: 900;
}
.mco-table-01__cell {
	border-bottom: 1px solid #003a56 !important;
	padding: 12px 16px 13px !important;
}
.mco-table-01__cell--sticky {
	background: #ffffff !important;
	max-width: none !important;
	min-width: 100px !important;
	padding-left: 0 !important;
	width: auto !important;
}
.mco-table-01__swipePopUp {
	background: rgba(0, 0, 0, 0.6) !important;
}
.mco-table-01__swipePopUp p {
	color: #ffffff !important;
}
.mco-table-01__swipePopUp svg path {
	fill: #ffffff !important;
}
@media all and (min-width: 960px) {
	.mco-table-01__headline {
		margin-bottom: 16px !important;
	}
}

/*** Tarife ***/

.mco-rates-01__row th {
	background: #003a56 !important;
	color: #ffffff !important;
}
.mco-rates-01__row td {
	background: #ffffff !important;
}

/*** Snowreport ***/

.mco-view-component-container-mski-snowreport {
	position: relative;
}
.mco-view-component-container-mski-snowreport .bg {
	height: 100%;
	left: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}
.mco-view-component-container-mski-snowreport .bg img {
	display: block;
	height: auto !important;
	mix-blend-mode: darken;
	position: relative;
	width: 100% !important;
}
.mco-view-component-container-mski-snowreport .bg::before {
	background: linear-gradient(180deg, rgba(247, 247, 248, 0) 60%, #f7f7f8 100%);
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}
.mco-view-component-container-mski-snowreport .bg::after {
	background: #003a56cc;
	content: "";
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.mco-snowreport-01 {
	padding-bottom: 48px !important;
	padding-top: 48px !important;
	position: relative;
	z-index: 1;
}
.mco-snowreport-01 > div:not(.mco-snowreport-01__info-provider) {
	border-bottom: 1px solid #003a56 !important;
	color: #ffffff !important;
}
.mco-snowreport-01__column-value,
.mco-snowreport-01__column-value p {
	font-weight: bold !important;
}
.mco-snowreport-01__column--avalanche .MuiAvatar-root {
	height: 40px !important;
	width: 40px !important;
}
.mco-snowreport-01__column--avalanche button {
	text-transform: uppercase !important;
}
.mco-snowreport-01__column--facilities,
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value,
.mco-snowreport-01__column--pists,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value {
	position: relative;
}
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value {
	cursor: help;
}
.mco-snowreport-01__column--facilities::before,
.mco-snowreport-01__column--pists::before {
	align-items: center;
	background: #ffffff;
	border-radius: 50%;
	color: #003a56;
	content: "i";
	display: flex;
	font-size: 10px;
	font-weight: 700 !important;
	height: 12px;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 8px;
	width: 12px;
}
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value::before,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value::before {
	background: #ffffff;
	content: "";
	height: 8px;
	opacity: 0;
	position: absolute;
	pointer-events: none;
	top: -15px;
	right: -8px;
	width: 8px;
	transform: rotate(45deg) translate(0px, -100%);
}
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value::after,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value::after {
	align-items: center;
	background: #ffffff;
	border-radius: 4px;
	color: #003a56;
	content: "inkl. Erpfendorf & Kirchdorf";
	display: flex;
	font-size: 12px;
	font-weight: 700 !important;
	line-height: 1.2;
	justify-content: center;
	padding: 8px;
	pointer-events: none;
	position: absolute;
	right: -16px;
	top: 0;
	opacity: 0;
	min-width: 130px;
	text-align: center;
	transform: translate(8px, calc(-100% + -16px));
}
html[lang="en"] .mco-snowreport-01__column--facilities .mco-snowreport-01__column-value::after,
html[lang="en"] .mco-snowreport-01__column--pists .mco-snowreport-01__column-value::after {
	content: "incl. Erpfendorf & Kirchdorf";
}
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value:hover::before,
.mco-snowreport-01__column--facilities .mco-snowreport-01__column-value:hover::after,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value:hover::before,
.mco-snowreport-01__column--pists .mco-snowreport-01__column-value:hover::after {
	opacity: 1;
}

@media all and (min-width: 960px) {
	.mco-snowreport-01 {
		padding-bottom: 80px !important;
		padding-top: 80px !important;
	}
}

/*** Liftstate ***/

.mco-view-component-container-mski-liftstate .mco-liftstate__facility-icon svg path {
	fill: #003a56 !important;
}
.mco-view-component-container-mski-liftstate .mco-liftstate__filter--open *,
.mco-view-component-container-mski-liftstate .mco-liftstate__filter--all * {
	color: #003a56 !important;
}
.mco-view-component-container-mski-liftstate .mco-liftstate__table-row th {
	background: #003a56 !important;
}
.mco-view-component-container-mski-liftstate .mco-liftstate__table-row th > * {
	color: #ffffff !important;
}
.mco-view-component-container-mski-liftstate .mco-liftstate__table {
	background: #ffffff !important;
}

/*** Piststate ***/

.mco-view-component-container-mski-piststate .mco-piststate__filter--open *,
.mco-view-component-container-mski-piststate .mco-piststate__filter--all * {
	color: #003a56 !important;
}
.mco-view-component-container-mski-piststate .mco-piststate__table-row th {
	background: #003a56 !important;
}
.mco-view-component-container-mski-piststate .mco-piststate__table-row th > * {
	color: #ffffff !important;
}
.mco-view-component-container-mski-piststate .mco-piststate__table {
	background: #ffffff !important;
}

/*** Anlagen ***/

.mco-view-component-container-mski-facilities .mco-facilities__facility-icon svg path {
	fill: #003a56 !important;
}
.mco-view-component-container-mski-facilities .mco-facilities__filter--open *,
.mco-view-component-container-mski-facilities .mco-facilities__filter--all * {
	color: #003a56 !important;
}
.mco-view-component-container-mski-facilities .mco-facilities__table-row th {
	background: #003a56 !important;
}
.mco-view-component-container-mski-facilities .mco-facilities__table-row th > * {
	color: #ffffff !important;
}
.mco-view-component-container-mski-facilities .mco-facilities__table {
	background: #ffffff !important;
}

/*** Webcams ***/

.mco-webcams__item {
	background: #ffffff !important;
}
.mco-webcams__item-content {
	padding: 16px !important;
}
.mco-webcams__item-content > p:first-child {
	font-family: "Nunito Sans" !important;
	font-weight: 900 !important;
	line-height: 1 !important;
	margin: 0 !important;
}
.mco-webcams__item-content > p:last-child {
	margin: 8px 0 0 !important;
}
.mco-webcams__item .MuiChip-root {
	left: auto !important;
	right: 8px !important;
}

/*** Weather ***/

.mco-view-component-container-mski-weather .mco-weater-02-day {
	background: #ffffff !important;
}
.mco-view-component-container-mski-weather .mco-weater-02-day h2 {
	color: #003a56 !important;
	font-size: clamp(24px, 8vw, 32px) !important;
}
.mco-view-component-container-mski-weather .mco-weater-02-day__item {
	background: #f7f7f8 !important;
}
.mco-view-component-container-mski-weather
	.mco-weater-02-day__times-wrapper
	> div:nth-child(4) {
	display: none !important;
}
.mco-view-component-container-mski-weather .mco-weater-02-day-item {
	background: #f7f7f8 !important;
	padding: 16px;
}
.mco-view-component-container-mski-weather .mco-weater-02-day__information-title,
.mco-view-component-container-mski-weather .mco-weater-02-day__information-value {
	line-height: normal !important;
}
.mco-view-component-container-mski-weather .mco-weater-02-day__information-value {
	color: #003a56 !important;
}
.mco-view-component-container-mski-weather .mco-weater-02-day-item__temperature-value,
.mco-view-component-container-mski-weather .mco-weater-02-day-item__temperature {
	color: #003a56 !important;
}

/*** Search ***/

.mco-search-results__item button {
	border-radius: 0 !important;
	display: flex !important;
}

/*** Routeplanner ***/

.mco-view-component-container-extension-route-planner {
	position: relative;
	z-index: -1;
}
.extension-route-planner__input input {
	margin-top: 4px !important;
}

/*** Overlay ***/

.mco-overlay-close {
	background: #e63311 !important;
}

/*** Recpatcha Badage ***/

.grecaptcha-badge {
	z-index: 1;
}

/*** Interaktionslayer ***/

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	align-items: center !important;
	background: #e63311 !important;
	border: 0 !important;
	border-radius: 24px !important;
	box-shadow: none !important;
	color: #ffffff !important;
	cursor: pointer !important;
	display: flex !important;
	font-family: "Nunito Sans" !important;
	font-size: 18px !important;
	font-weight: 800 !important;
	height: 48px !important;
	letter-spacing: 0.4px !important;
	line-height: 1 !important;
	margin-left: auto !important;
	min-width: 64px !important;
	padding: 8px 24px !important;
	transition: background 300ms ease !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: #a3240c !important;
}
.micado-overlay__close,
.micado-ial__close {
	background: #e63311 !important;
}
.micado-overlay__close:hover,
.micado-ial__close:hover {
	background: #a3240c !important;
}
.micado-ial-item-message__headline,
.micado-ial-item-contact__headline,
.micado-ial-item-countdown__headline,
.micado-ial-item-newsletter__headline,
.micado-ial-item-offer__headline {
	color: #003a56 !important;
	font-family: "Nunito Sans" !important;
	font-weight: 900 !important;
}
.micado-ial-item * p {
	line-height: normal !important;
}
