body:not(.mco-ski-shop-widget01-root) .mco-ski-shop-ticketlist01 {
	min-height: 100vh;
}
body:not(.mco-ski-shop-widget01-root) .mco-ski-shop-filterlist01::before {
	background: rgba(0, 58, 86, 0.8);
	content: "";
	position: absolute;
	pointer-events: none;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 2;
}
.mco-ski-shop-productlist01-product__no-image img {
	object-fit: contain !important;
}
.mco-ski-shop-productlist01__type-title {
	color: var(--mco-palette-secondary-main) !important;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
}
.mco-ski-shop-productlist01-product {
	background: var(--mco-palette-background-light) !important;
	min-height: 175px;
}
.mco-ski-shop-productlist01-product__consumer-category-title {
	font-size: 16px !important;
}
body:not(.mco-ski-shop-widget01-root) .mco-ski-shop-filterlist01__date-item > div > span {
	font-size: 18px !important;
}
.mco-ski-shop-productlist01-family-check__item {
	background: var(--mco-palette-background-light) !important;
}
