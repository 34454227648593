/* noto-sans-regular - latin */
@font-face {
	font-display: block;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/noto-sans-v26-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/noto-sans-v26-latin-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-600 - latin */
@font-face {
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/noto-sans-v26-latin-600.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/noto-sans-v26-latin-600.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-700 - latin */
@font-face {
	font-display: block;
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/noto-sans-v26-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/noto-sans-v26-latin-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-regular - latin */
@font-face {
	font-display: block;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/nunito-sans-v11-latin-regular.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/nunito-sans-v11-latin-regular.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-600 - latin */
@font-face {
	font-display: block;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/nunito-sans-v11-latin-600.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/nunito-sans-v11-latin-600.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin */
@font-face {
	font-display: block;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/nunito-sans-v11-latin-700.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/nunito-sans-v11-latin-700.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-800 - latin */
@font-face {
	font-display: block;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 800;
	src: local(""), url("../fonts/nunito-sans-v11-latin-800.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/nunito-sans-v11-latin-800.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-900 - latin */
@font-face {
	font-display: block;
	font-family: "Nunito Sans";
	font-style: normal;
	font-weight: 900;
	src: local(""), url("../fonts/nunito-sans-v11-latin-900.woff2") format("woff2"),
		/* Chrome 26+, Opera 23+, Firefox 39+ */ url("../fonts/nunito-sans-v11-latin-900.woff")
			format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
